import React from "react"
import { Link } from "gatsby"

const NavItem = ({ label, fallback, id, onVisited, visited, isNode }) => {
  const color = visited ? "text-black" : " text-gray-500"
  return (
    <li
      className={`mx-6  ${color} text-sm hover:text-black cursor-pointer uppercase  sm:leading-10 leading-8`}
    >
      {isNode ? (
        <a
          onClick={e => {
            e.preventDefault()
            onVisited(id)
          }}
        >
          {label}
        </a>
      ) : (
        <Link to={fallback}> {label}</Link>
      )}
    </li>
  )
}

export default NavItem
