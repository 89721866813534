import React from "react"
import { Link } from "gatsby"
import NavItem from "./NavItem"

const MobileNav = ({ routeRefs }) => {
  const [isOpen, setOpen] = React.useState(false)
  const [visitedLink, setVisited] = React.useState(null)

  const open = () => setOpen(true)
  const close = () => setOpen(false)

  const fadeNav = isOpen ? "nav-active" : "nav-inactive"
  const fadeBtnOpen = isOpen ? "nav-btn-active" : "nav-btn-inactive"
  const fadeBtnClose = isOpen ? "nav-btn-inactive" : "nav-btn-active"

  const links = [
    {
      id: 1,
      label: "stay",
      node: routeRefs?.stayRef,
      fallback: "/#stay",
    },
    {
      id: 2,
      label: "dining",
      node: routeRefs?.dinningRef,
      fallback: "/#dinning",
    },
    {
      id: 3,
      label: "Things To do",
      node: routeRefs?.activitiesRef,
      fallback: "/#activities",
    },
    {
      id: 4,
      label: "gallery",
      node: "/gallery",
      node: routeRefs?.exploreRef,
      fallback: "/#explore",
    },
    {
      id: 5,
      label: "contact",
      node: "contact",
      fallback: "/#contact",
    },
  ]

  const onVisited = (id, node) => {
    setVisited(id)
    if (typeof node === "string") {
      return
    }

    node.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div>
      <div>
        {isOpen ? (
          <div onClick={close} class={`${fadeBtnOpen} hover:bg-gray-50 p-1`}>
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </div>
        ) : (
          <div onClick={open} class={`${fadeBtnClose}  hover:bg-gray-50 p-1`}>
            <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
              />
            </svg>
          </div>
        )}
      </div>

      <div
        style={{ zIndex: 4 }}
        class={`absolute left-0 right-0 px-8 py-12 bg-white w-full flex items-center justify-center ${fadeNav}  rounded-lg shadow-md overflow-hidden`}
      >
        <ul clas="_mt-2">
          {links.map(link => (
            <NavItem
              key={link.id}
              {...link}
              onVisited={id => onVisited(id, link.node)}
              visited={link.id === visitedLink}
              isNode={Boolean(
                link.node?.current && typeof link.node !== "string"
              )}
            />
          ))}

          <li className="py-4">
            <Link to="/book">
              <button
                class=" px-5  py-2 flex items-center justify-center rounded-md  bg-green-800 text-white font-bold"
                type="submit"
              >
                Book Now
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileNav
