import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="pt-20 px-6 w-full h-screen bg-green-50 flex items-center justify-center">
        <div>
          <div>
            <span className="text-3xl uppercase ">404</span>
          </div>
          <span className="text-xl">Page not Found</span>

          <div className="py-5">
            <Link to="/">
              <button
                class=" px-5  py-2 flex items-center justify-center rounded-md  bg-green-800 text-white font-bold"
                type="submit"
              >
                Go Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
