import React from "react"
import NavList from "./NavList"
import MobileNav from "./MobileNav"
import { Link } from "gatsby"

const Header = ({ routeRefs }) => {
  return (
    <div className=" bg-white fixed w-full px-6 py-4 flex items-center mx-auto justify-between shadow-md header ">
      <Link to="/">
        <div className="flex items-center lg:relative md:relative sm:absolute z-30">
          <img src="/images/logo.jpg" width={60} />
          <div className="px-2">
            <h3 className="text-lg lg:text-xl hidden lg:block">
              The Palm Beach Resort
            </h3>
          </div>
        </div>
      </Link>
      <div className="w-1/2 ml-8 mr-6 hidden lg:block ">
        <NavList routeRefs={routeRefs} />
      </div>

      <div className="lg:hidden sm:block">
        <MobileNav routeRefs={routeRefs} />
      </div>

      <div className="hidden lg:block">
        <Link to="/book">
          <button
            class=" focus:outline-none hover:outline-none  px-5  py-2 flex items-center justify-center rounded-md  bg-green-800 text-white font-bold"
            type="submit"
          >
            Book Now
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Header
