import React from "react"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children, routeRefs }) => {
  return (
    <div>
      <Header routeRefs={routeRefs} />
      <div class="content pt-20">
        <div class="content-inside">{children}</div>
      </div>
      <div class="pt-3 w-screen">
        <Footer />
      </div>
    </div>
  )
}

export default Layout
